// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import I18n from "~/shared/i18n";

function mergeOptionsAndVariables(options, variables) {
  return Object.assign(options, variables);
}

function options(count, identifier) {
  var defaultScope = Js_dict.fromArray([[
          "scope",
          "shared." + identifier
        ]]);
  return {
          count: count,
          defaults: [defaultScope]
        };
}

function t(scope, variablesOpt, count, identifier) {
  var variables = variablesOpt !== undefined ? variablesOpt : [];
  var fullOptions = mergeOptionsAndVariables(options(count, identifier), Js_dict.fromArray(variables));
  var fullIdentifier = scope !== undefined ? scope + ("." + identifier) : identifier;
  return I18n.t(fullIdentifier, fullOptions);
}

function ts(variablesOpt, count, identifier) {
  var variables = variablesOpt !== undefined ? variablesOpt : [];
  return t("shared", variables, count, identifier);
}

export {
  t ,
  ts ,
}
/* ~/shared/i18n Not a pure module */
